h1 {
  font-size: 2em;
  text-transform: uppercase;
}

h2 {
  font-size: 1.5em;
  text-transform: uppercase;
}

h3 {
  font-size: 1.17em;
  text-transform: uppercase;
}

h4 {
  font-size: 1.00em;
  text-transform: uppercase;
}

h5 {
  font-size: 0.83em;
  text-transform: uppercase;
}

h6 {
  font-size: 0.67em;
  text-transform: uppercase;
}

body, html {
  width: 100%;
  height: 100%;
}

.App-content {
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin: 50px;
}

.Texts {
  padding: 50px 20px 100px 20px;
  max-width: 970px;
  font-size: 16px;
  color: #202020;
  text-align: left;
  background-color: #ffffff;
  box-shadow: 0px 10px 50px rgba(0,0,0,0.1);
}

.Texts h1 {
  text-align: left;
}

/* The hero image */
.hero-image {
/* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
background-image: url("./images/hero.jpg");

/* Set a specific height */
height: 70vh;

/* Position and center the image to scale nicely on all screens */
background-position: center;
background-repeat: no-repeat;
background-size: cover;
position: relative;
}

/* Place text in the middle of the image */
.hero-text {
text-align: center;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
color: white;
}
